<div class="d-flex align-items-center justify-content-between mb-1 pr-2">
  <div class="">
    <div class="content-header">
      <h1>{{'PROFILE' | translate}}</h1>
    </div>
  </div>
  <div *ngIf="!user?.provider" class="">
    <h4 class="widget-title">
      <button class="btn btn-success btn-big" (click)="changePassword()">
        <!-- <i class="fas fa-key mr-2"></i> -->
        {{'CHANGE_PASSWORD' | translate}}
      </button>
    </h4>
  </div>
</div>
<form [formGroup]="userForm">

  <div class="row flex-column-reverse flex-sm-row">
    <div class="col-sm-7 col-md-8 col-lg-8 col-xl-9 mt-3 mt-sm-0">
      <div class="box box-primary">
        <div class="box-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group ">
                <label>{{'FIRST_NAME' | translate}}</label>
                <input formControlName="firstName" class="form-control" type="text">
                <div *ngIf="userForm.get('firstName').touched && userForm.get('firstName').errors">
                  <div class="text-danger" *ngIf="userForm.get('firstName').errors?.required">
                    {{'FIRST_NAME_IS_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label>{{'LAST_NAME' | translate}}</label>
                <input formControlName="lastName" class="form-control" type="text">
                <div *ngIf="userForm.get('lastName').touched && userForm.get('lastName').errors">
                  <div class="text-danger" *ngIf="userForm.get('lastName').errors?.required">
                    {{'LAST_NAME_IS_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label>{{'MOBILE_NUMBER' | translate}}</label>
                <input formControlName="phoneNumber" class="form-control" type="number">
                <div *ngIf="userForm.get('phoneNumber').touched && userForm.get('phoneNumber').errors">
                  <div class="text-danger" *ngIf="userForm.get('phoneNumber').errors?.required">
                    {{'MOBILE_IS_REQUIRED' | translate}}
                  </div>
                  <div class="text-danger" *ngIf="userForm.get('phoneNumber').errors?.pattern">
                    {{'MOBILE_NUMBER_MUST_HAVE_10_DIGITS' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'EMAIL' | translate}}</label>
                <input [attr.disabled]="true" formControlName="email" class="form-control" type="email">
              </div>
            </div>
            <!-- <div class="form-group col-md-12">
              <label>{{'ADDRESS' | translate}}</label>
              <textarea formControlName="address" class="form-control"></textarea>
            </div> -->
            <div class="m-top-10 col-md-6">
              <button class="btn btn-success btn-big m-right-10" (click)="updateProfile()" cdkFocusInitial><i
                  class="fas fa-save"></i>
                  <span *ngIf="!disabled"> {{'SAVE' | translate }}</span> 
                  <mat-spinner class="mat-spinner-color-white" color="primary" [diameter]="25" *ngIf="disabled"></mat-spinner>
                  </button>
              <button type="button" class="btn btn-danger btn-big " [routerLink]="['/']"><i
                  class="fas fa-times-circle"></i>
                {{'CANCEL' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-5 col-md-4 col-lg-4 col-xl-3 mt-3 mt-sm-0">
      <div class="justify-content-center">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{'PROFILE_PHOTO' | translate}}</mat-card-title>
          </mat-card-header>
          <input hidden (change)="fileEvent($event)" #fileInput name="logo" type="file" name="Profile Photo"
            fxFlex="30">
          <img loading="lazy" *ngIf="imgURL" class="full-width" [src]="imgURL" />
          <!-- <img *ngIf="!imgURL" class="full-width" src="../../../assets/images/user-profile.jpg" /> -->
          <div *ngIf="!imgURL" class="media-image-wrap-footer cursor-pointer" (click)="fileInput.click()">
            <div class="box-wrap text-center" id="lnkAddMenuCoverimage">
            <i class="fa fa-plus"></i>
            <p>Add photo</p>
            </div>
            </div>
          <mat-card-actions class="d-flex mx-0 mt-2 mb-0">
            <!-- <button *ngIf="!imgURL" class="btn btn-success btn-sm m-right-10" (click)="fileInput.click()">
              <i class="far fa-image"></i> {{'ADD_PHOTO' | translate}}</button> -->
            <button *ngIf="imgURL" class="btn btn-success btn-big m-right-10 flex-1" (click)="fileInput.click()">
              <!-- <i class="far fa-image mr-2"></i>  -->
              {{'CHANGE_PHOTO' | translate}}</button>
            <button *ngIf="imgURL" type="button" (click)="removeImage()" class="btn btn-danger btn-sm">
              <i class="fas fa-trash-alt"></i></button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

</form>