<div class="row align-items-center justify-content-between">
    <div class="col-md-auto col-sm-auto">
      <div class="content-header">
        <h1>Add a Match</h1>
      </div>
    </div>
   
  </div>
  
  <div class="box box-primary">
    <div class="box-body">
        <div class="tournament-p-wrap">
        <div class="row flex-column">
          <div class="row">
            <div class="form-group col-md-12 mb-3">
                <div class="d-flex flex-column">
                   <label class=" d-flex mb-2">
                    Choose Tournament
                   </label>
                   <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/en/thumb/e/eb/2023_CWC_Logo.svg/200px-2023_CWC_Logo.svg.png" height="50"/>
                      ICC Men's World Cup 2023
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem>  <img loading="lazy" src="https://upload.wikimedia.org/wikipedia/en/thumb/e/eb/2023_CWC_Logo.svg/200px-2023_CWC_Logo.svg.png" height="50"/> ICC Men's World Cup 2023 </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>

            <div class="row">
            <div class="form-group col-md-6 mb-3 align-items-center">
                <label class=" mb-1">
                    Match No.
                  </label>
                  <input  type="text" class="form-control mb-1 mr-0 mr-md-3 flex-1">
              </div>
              <div class="form-group col-md-6 mb-3 align-items-center">
                <label class=" mb-0">
                    Status
                  </label>
                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      <span class="statusChangeLink mr-2 status-color-cancelled"></span>
                      Cancelled
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem><span class="statusChangeLink mr-2 status-color-scheduled"></span> Scheduled </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-delayed"></span> Delayed </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-stopped"></span> Stopped </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-live"></span> Live </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-finished"></span> Finished </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-rescheduled"></span> Rescheduled </button>
                      <button ngbDropdownItem> <span class="statusChangeLink mr-2 status-color-cancelled"></span> Cancelled </button>
                    </div>
                  </div>
              </div>
            </div>

            <div class="row">
                <div class="form-group col-md-6 mb-3 dropdown-with-image">
                    <label class=" mb-0">
                        Team 1
                      </label>   
                      <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                        <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                          <img loading="lazy" src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>
                          Australia
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                          <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg"  height="50"/> Australia </button>
                          <button ngbDropdownItem><img src="https://img.freepik.com/free-vector/illustration-pakistan-flag_53876-27123.jpg?w=740&t=st=1686215708~exp=1686216308~hmac=f845fc5646eb22f647b85180582e73a8e3cbde63f7749599d714ba47f8c003ce"  height="50"/> Pakistan </button>
                        </div>
                      </div>
                  </div>
                  <div class="form-group col-md-6 mb-3 ">
                    <label class=" mb-0">
                      Team 2
                    </label>  
                    <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                      <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                        <img loading="lazy" src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>
                        Australia
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                        <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg"  height="50"/> Australia </button>
                        <button ngbDropdownItem><img src="https://img.freepik.com/free-vector/illustration-pakistan-flag_53876-27123.jpg?w=740&t=st=1686215708~exp=1686216308~hmac=f845fc5646eb22f647b85180582e73a8e3cbde63f7749599d714ba47f8c003ce"  height="50"/> Pakistan </button>
                      </div>
                    </div>
                  </div>
                </div>

            <div class="row">
              
                <div class="form-group col-md-6 mb-3 ">
                 
                  <label class=" mb-0">           
                    Match Referee
                  </label>
                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      <img loading="lazy" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" height="50"/>
                      Choose Referee
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem> <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" height="50"/>Choose Referee</button>
                      <!-- <button ngbDropdownItem>Choose Referee</button> -->
                    </div>
                  </div>
                  </div>
                  <div class="form-group col-md-6 mb-3 ">
                    <label class=" mb-0">
                        Where it is held?
                      </label>
                      <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                        <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                          <!-- <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" height="50"/> Toggle dropdown -->
                          Choose venue
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <!-- <button ngbDropdownItem> <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" height="50"/>Action - 1</button> -->
                          <button ngbDropdownItem>Choose venue</button>
                        </div>
                      </div>
                  </div>
                </div>
              
                <div class="row">
                    <div class="form-group col-md-6 mb-4 date-picker">
                      <div class="d-flex flex-column">
                        <label class=" mb-1">
                          Scheduled date
                        </label>
                        <div class="date-picker-wrap">
                          <input class="form-control" type="date"/>
                        </div>
                      
                      </div>
                    </div>

                    <div class="form-group col-md-6 mb-4 date-picker">
                      <div class="d-flex flex-column">
                        <label class=" mb-1">
                          Start Time
                        </label>
                        <div class="date-picker-wrap">
                          <input class="form-control" type="time"/>
                          <!-- <input [owlDateTime]="dt2" class="form-control" [owlDateTimeTrigger]="dt2" >
                          <owl-date-time #dt2></owl-date-time>
                          <div class="date-icon"><mat-datepicker-toggle [owlDateTimeTrigger]="dt2"></mat-datepicker-toggle></div>
                           -->
                        </div>
                      </div>
                    </div>
                  </div>
           
                  <!-- <div class="row">
                    <div class="form-group col-md-6 mb-4 date-picker">
                      <div class="d-flex flex-column">
                        <label class=" mb-1">
                          Start Time
                        </label>
                        <div class="date-picker-wrap">
                          <input class="form-control" type="time"/>
                          <input [owlDateTime]="dt2" class="form-control" [owlDateTimeTrigger]="dt2" >
                          <owl-date-time #dt2></owl-date-time>
                          <div class="date-icon"><mat-datepicker-toggle [owlDateTimeTrigger]="dt2"></mat-datepicker-toggle></div>
                           
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6 mb-4 date-picker">
                      <div class="d-flex flex-column">
                        <label class=" mb-1">
                          End Time
                        </label>
                        <div class="date-picker-wrap">
                          <input class="form-control" type="time"/>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  
                  <div class="row">
                  <div class="form-group col-md-12 mb-4">
                    <div class="d-flex flex-column">
                        <label class=" mb-0">
                            Audio Stream URL
                          </label>
                          <input  type="text" class="form-control mb-1 mr-0 mr-md-3 flex-1">
                    </div>
                  </div>
                  </div>

                  <div class="row">
                  <div class="form-group col-md-12 mb-4">
                    <div class="d-flex flex-column">
                        <label class=" mb-0">
                            Recorded Audio URL
                          </label>
                          <input  type="text" class="form-control mb-1 mr-0 mr-md-3 flex-1">
                    </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="form-group col-md-12 mb-4">
                    <div class="d-flex flex-column">
                        <label class=" mb-1">
                            Match Result Summary
                          </label>
                          <textarea  rows="4" placeholder="Write match result summary here." class="form-control"></textarea>
                    </div>
                  </div>
                </div>


                 <div class="m-top-10 col-md-12">
                  <ng-container >
                    <button class="btn btn-success btn-sm m-right-10 btn-big " >
                      <!-- <i class="fas fa-save"></i> -->
                       Save
                      </button>
                      <button class="btn btn-success btn-sm m-right-10 btn-big" >
                        <!-- <i class="fas fa-save"></i> -->
                        Save & Add another
                        </button>
                      <button type="button" class="btn btn-outline-secondary btn-sm margin-left-10 btn-big">
                        <!-- <i class="fas fa-trash-alt"></i> -->
                       Cancel
                      </button>
                  </ng-container>
                 
                </div>

        </div>
        </div>
        </div>
        </div>