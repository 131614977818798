<!-- <div class="close-btn-modal" (click)="clickHandler(false)">
  <i class="fa fa-times"></i>
</div>
<div class="container">
  <span md-dialog-title>{{primaryMessage}}</span>
  <div mat-dialog-actions class="mt-2">
    <button class="btn btn-success btn-sm m-right-10" (click)="clickHandler(true)"><i class="fas fa-check"></i>
      Yes</button>
    <button class="btn btn-danger btn-sm" (click)="clickHandler(false)"><i class="fas fa-times-circle"></i>
      Cancel</button>
  </div>
</div> -->


<div class="modal-small">
  <div class="modal-header">
    <h5 class="modal-title font-weight-bold">Confirmation</h5>
    <button type="button"  (click)="clickHandler(false)" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{primaryMessage}}</p>
  </div>
  <div class="modal-footer pb-0">
    <button (click)="clickHandler(true)" class="btn btn-success btn-sm">Yes</button>
      <button (click)="clickHandler(false)" class="btn btn-danger btn-sm" >Cancel</button>
  </div>
</div> 