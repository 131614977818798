<div class="wrapper">
  <app-loading-indicator></app-loading-indicator>
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-wrapper">
    <div class="main-container content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>