<div class="row align-items-center justify-content-between">
    <div class="col-md-auto col-sm-auto">
      <div class="content-header">
        <h1>{{'ADD_TOURNAMENTS' | translate }}</h1>
      </div>
    </div>
   
  </div>
  
  <div class="box box-primary">
    <div class="box-body">
      <div class="tournament-p-wrap">
        <a class="tournament-logo d-flex  mb-3 mt-3 flex-column">
             
  <!-- <mat-card> -->
    <!-- <mat-card-header>
      <mat-card-title>Profile Photo</mat-card-title>
    </mat-card-header> -->
    <input hidden (change)="fileEvent($event)" #fileInput name="logo" type="file" name="Profile Photo"
      fxFlex="30">
      <div *ngIf="imgURL" class="add-logo-wrap" (click)="fileInput.click()">
        <div class="box-wrap text-center" id="lnkAddMenuCoverimage">
          <i class="fa fa-plus"></i><p class="mb-0">{{'ADD_LOGO' | translate }}</p>
        </div>
      </div>
      
      <div class="position-relative d-flex flex-column justify-content-center align-items-center cursor-pointer">
        <div class="w-100 w-min-100 mb-2">

          <img *ngIf="imgURL" class="full-width" [src]="imgURL" style="height: 100px;" />
          <!-- <img loading="lazy" src="https://img.freepik.com/premium-vector/great-britain-flag_171867-201.jpg?w=2000"/> -->
        </div>
        <div class="d-flex w-100 align-items-center mt-2">
          <button *ngIf="imgURL" class="btn btn-success btn-big m-right-10" (click)="fileInput.click()">
            <!-- <i class="far fa-image mr-2"></i> -->
            {{'CHANGE_PHOTO' | translate }}</button>
            <div class="delete-icon-t  btn btn-danger btn-sm margin-left-10" (click)="removeImage()" ><i class="fas fa-trash-alt"></i></div>
          <!-- <button *ngIf="imgURL" type="button" (click)="removeImage()" class="btn btn-danger btn-sm">
            <i class="fas fa-trash-alt"></i> Remove Photo</button> -->
        </div>
      </div>
   
    <!-- <img *ngIf="!imgURL" class="full-width" src="../../../assets/images/user-profile.jpg" /> -->
    <!-- <mat-card-actions>
    <button *ngIf="!imgURL" class="btn btn-success btn-sm m-right-10" (click)="fileInput.click()">
        <i class="far fa-image"></i> Add photo</button> 
       <button *ngIf="imgURL" class="btn btn-success btn-sm m-right-10" (click)="fileInput.click()">
        <i class="far fa-image"></i> Change Photo</button>
      <button *ngIf="imgURL" type="button" (click)="removeImage()" class="btn btn-danger btn-sm">
        <i class="fas fa-trash-alt"></i> Remove Photo</button>
    </mat-card-actions> -->
  <!-- </mat-card> -->

        </a>

        <div class="row flex-column">
            <div class="form-group col-md-8 mb-3">
                <label>{{'NAME' | translate }}</label>
                <input  type="text" class="form-control">
            </div>
           
              <div class="form-group col-md-8 mb-3">
                <label class="">
                  {{'DESCRIPTION' | translate }}
                  </label>
              <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" formControlName="body">
                </ngx-editor>
              </div>
              </div>

              <div class="row">
              <div class="form-group col-md-6 mb-3">
                <div class="d-flex flex-column">
                <label class=" d-flex mb-2">
                  {{'Type' | translate }}
                  </label>
                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      League
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem>League </button>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="form-group col-md-6 mb-4">
                <div class="d-flex flex-column date-picker">
                <label class=" d-flex mb-2">
                  {{'START_DATE' | translate }}
                  </label>
                  <div class="date-picker-wrap">
                    <input class="form-control" type="date"/>
                    
                  </div>
                </div>
              </div>
            </div>

              <div class="row">
                <div class="form-group col-md-6 mb-3">
                  <div class="d-flex flex-column">
                  <label class=" d-flex mb-2">
                    {{'BELONGS_TO_WHICH_COUNTRY' | translate }}
                    </label>
                    <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                      <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                        <img loading="lazy" src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>
                        Australia
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                        <button ngbDropdownItem> <img loading="lazy" src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg"  height="50"/> Australia </button>
                        <button ngbDropdownItem><img loading="lazy" src="https://img.freepik.com/free-vector/illustration-pakistan-flag_53876-27123.jpg?w=740&t=st=1686215708~exp=1686216308~hmac=f845fc5646eb22f647b85180582e73a8e3cbde63f7749599d714ba47f8c003ce"  height="50"/> Pakistan </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-6 mb-3">
                  <div class="d-flex flex-column">
                  <label class=" d-flex mb-2">
                    {{'WHERE_IT_IS_HELD' | translate }}
                    </label>
                    <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                      <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                        Choose venue
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                        <button ngbDropdownItem>Choose venue</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- <div class="row">
                <div class="form-group col-md-6 mb-4">
                  <div class="d-flex flex-column date-picker">
                  <label class=" d-flex mb-2">
                    Start date
                    </label>
                    <div class="date-picker-wrap">
                      <input class="form-control" type="date"/>
                      
                    </div>
                  </div>
                </div>

                <div class="form-group col-md-6 mb-4">
                  <div class="d-flex flex-column">
                  <label class=" d-flex mb-2">
                    End date
                    </label>
                    <mat-form-field>
                      <mat-label>Choose a date</mat-label>
                      <input matInput [matDatepicker]="picker">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> -->

              <div class="form-group col-md-8 mb-3">
                <div class="d-flex mb-2">
                <label class=" d-flex mb-2">
                  {{'Are_there_any_Pools_in_this_Tournament' | translate }}
                  </label>
                  <mat-slide-toggle class="ml-auto">
                  </mat-slide-toggle>
                </div>
                <div class="d-flex align-items-center">
                <input  type="text" placeholder="Enter pool name" class="form-control mb-1 mr-3 flex-1">
                <a class="text-primary cursor-pointer"> {{'add another' | translate }}</a>
              </div>
              </div>

              <div class="form-group col-md-8 mb-4">
                <label class=" d-flex mb-2">
                  {{'TEAMS_PLAYING_IN_THIS_TOURNAMENT' | translate }}
                  </label>
                  <div class="form-inline">
                    <div class="input-group w-100" >
                      <input class="form-control form-control-sidebar"  placeholder="Search Teams" aria-label="Search">
                      <div class="input-group-append">
                        <button class="btn btn-sidebar">
                          <i class="fas fa-search fa-fw"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- table starts  -->
                <div class="form-group col-md-8 mb-3">
                  <div class="tournament-team-m-wrap d-flex align-items-center mb-1">
                  <div class="tournament-logo-i mr-1">
                      <img loading="lazy" src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" alt="Australia">
                  </div>
                  <div class="tournament-txt mr-4">
                  <span>Australia</span>
                </div>
                <div class="tournament-dropdown mr-3 w-100">
                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      Pool A
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem>Pool B</button>
                    </div>
                  </div>
              </div>
              <div class="delete-icon-t ml-auto btn btn-danger btn-sm margin-left-10">
                
                  <i class="fas fa-trash-alt cursor-pointer"></i>
              
              </div>
                </div>
                <div class="tournament-team-m-wrap d-flex align-items-center mb-1">
                  <div class="tournament-logo-i mr-1">
                      <img loading="lazy" src="https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg?size=626&ext=jpg" alt="Australia">
                  </div>
                  <div class="tournament-txt mr-4">
                  <span>England</span>
                </div>

                <div class="tournament-dropdown mr-3 w-100">
                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      Pool A
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem>Pool B</button>
                    </div>
                  </div>
              </div>

              <div class="delete-icon-t ml-auto btn btn-danger btn-sm margin-left-10">
                <i class="fas fa-trash-alt cursor-pointer"></i>
              </div>
                </div>
                <div class="tournament-team-m-wrap d-flex align-items-center mb-1">
                  <div class="tournament-logo-i mr-1">
                      <img loading="lazy" src="https://img.freepik.com/free-vector/illustration-pakistan-flag_53876-27123.jpg?w=740&t=st=1686215708~exp=1686216308~hmac=f845fc5646eb22f647b85180582e73a8e3cbde63f7749599d714ba47f8c003ce" alt="Australia">
                  </div>
                  <div class="tournament-txt mr-4">
                  <span>Pakistan</span>
                </div>
                <div class="tournament-dropdown mr-3 w-100">

                  <div ngbDropdown class="d-inline-block w-100 custom-b-dropdown">
                    <button  type="button" class="btn btn-outline-primary w-100 c-dropdown-img" id="dropdownBasic1" ngbDropdownToggle>
                      Pool A
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <button ngbDropdownItem> <img src="https://cdn.britannica.com/78/6078-004-77AF7322/Flag-Australia.jpg" height="50"/>Action - 1</button> -->
                      <button ngbDropdownItem>Pool B</button>
                    </div>
                  </div>

              </div>
              <div class="delete-icon-t ml-auto btn btn-danger btn-sm margin-left-10">
                <i class="fas fa-trash-alt cursor-pointer"></i>
              </div>
                </div>
                </div>
                 <!-- table Ends  -->
                 <div class="m-top-10 col-md-12">
                  <ng-container >
                    <button class="btn btn-success btn-sm m-right-10 btn-big " >
                      <!-- <i class="fas fa-save"></i>  -->
                      {{'SAVE' | translate }}</button>
                      <button type="button" class="btn btn-outline-secondary btn-sm margin-left-10 btn-big ">
                        <!-- <i class="fas fa-trash-alt"></i> -->
                        {{'CANCEL' | translate }}
                      </button>
                  </ng-container>
                 
                </div>

        </div>
      </div>
        </div>
        </div>