<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item d-none">
      <a class="nav-link" data-widget="fullscreen" role="button">
        <i class="fas fa-expand-arrows-alt"></i>
      </a>
    </li>
    <li class="nav-item dropdown d-none" dropdown>
      <a class="dropdown-toggle nav-link" dropdownToggle id="button-animated" aria-controls="dropdownMenuButton">
        <img class="contry-flag rounded" src="{{ language.flag }}" alt="language" /> {{ language.name }}
      </a>
      <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
        <ng-container *ngFor="let language of languages">
          <li role="button" class="dropdown-item" (click)="setNewLanguageRefresh(language.lang)">
            <img class="contry-flag" src="{{ language.flag }}"> {{ language.name }}
          </li>
        </ng-container>
      </ul>
    </li>
    <li class="nav-item dropdown user-menu" dropdown>
      <a class="dropdown-toggle nav-link d-flex" dropdownToggle id="button-animated-1">
        <!-- <img *ngIf="appUserAuth.profilePhoto" class="user-image a " [src]="appUserAuth.profilePhoto"> -->
        <img *ngIf="!appUserAuth.profilePhoto" class="user-image b" src="assets/images/user-profile.jpg">
       
        <div class="user-image-bg" *ngIf="appUserAuth.profilePhoto" [ngStyle]="{'background-image': 'url(&quot;' + appUserAuth.profilePhoto + '&quot;)'}">

        </div>
        <span class="">{{appUserAuth.firstName}} {{appUserAuth.lastName}}</span>
      </a>
      <ul class="dropdown-menu user-profile" *dropdownMenu role="menu" aria-labelledby="button-animated-1">
        <!-- User image -->
        <li class="user-header">
          <!-- <img *ngIf="appUserAuth.profilePhoto" class="img-circle" [src]="appUserAuth.profilePhoto"> -->
          <img *ngIf="!appUserAuth.profilePhoto" class="img-circle" src="assets/images/user-profile.jpg">
          <div class="user-image-bg h-dropdown-img" *ngIf="appUserAuth.profilePhoto" [ngStyle]="{'background-image': 'url(&quot;' + appUserAuth.profilePhoto + '&quot;)'}">

          </div>
          <p>
            {{appUserAuth.firstName}} {{appUserAuth.lastName}}
          </p>
        </li>
        <li class="user-footer">
          <div class="pull-left">
            <a [routerLink]="['my-profile']" class="btn btn-success btn-big text-white m-right-10">Profile</a>
          </div>
          <div class="pull-right">
            <a (click)="onLogout()" class="btn btn-outline-secondary btn-big">Logout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</nav>
<!-- /.navbar -->