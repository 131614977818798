import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Editor } from 'ngx-editor';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-add-match',
  templateUrl: './add-match.component.html',
  styleUrls: ['./add-match.component.scss']
})
export class AddMatchComponent extends BaseComponent implements OnInit {
  foods: Food[] = [
    { value: "steak-0", viewValue: "Steak" },
    { value: "pizza-1", viewValue: "Pizza" },
    { value: "tacos-2", viewValue: "Tacos" }
  ];

  selected = "https://img.freepik.com/premium-vector/vector-image-british-flag-england-sign-kingdom-great-britain-lovely-london-badge_213497-1010.jpg";

  editor: Editor;
  html: '';
  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }

}
