<!-- <div class="close-btn-modal" (click)="onNoClick()">
  <i class="fa fa-times"></i>
</div> -->
<div class="abc">
  <div class="modal-header">
  <h3 class="mb-0" mat-dialog-title>{{'CHANGE_PASSWORD' | translate}}</h3>
  <button  type="button" data-dismiss="modal" aria-label="Close" class="close" (click)="onNoClick()">
    <span  aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body" style="overflow-y: auto; overflow-x: hidden; max-height: calc(100vh - 260px);">
  <div class="" mat-dialog-content>
    <form [formGroup]="changePasswordForm">
      <div class="col-md-12 px-0">
        <div class="form-group">
          <label>{{'EMAIL' | translate}}</label>
          <input [attr.disabled]="true" formControlName="email" class="form-control" type="email">
        </div>
      </div>
      <div class="col-md-12 px-0">
        <div class="form-group">
          <div class="input-group">
          <label class="w-100">{{'CURRENT_PASSWORD' | translate}}</label>
          <input placeholder="{{'PH_ENTER_CURRENT_PASSWORD' | translate}}" autocomplete="new-password" [type]="fieldTextType1 ? 'text' : 'password'" formControlName="oldPasswordPassword" class="form-control" type="password">
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer " (click)="fieldTextType1=!fieldTextType1">
              <i class="fa" [ngClass]="{'fa-eye-slash':!fieldTextType1,'fa-eye': fieldTextType1}"></i>
            </span>
          </div>
        </div>
          <div
            *ngIf="changePasswordForm.get('oldPasswordPassword').touched && changePasswordForm.get('oldPasswordPassword').errors">
            <div class="text-danger" *ngIf="changePasswordForm.get('oldPasswordPassword').errors?.required">
              {{'CURRENT_PASSWORD_IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-0">
        <div class="form-group">
          <label>{{'NEW_PASSWORD' | translate}}</label>
          <div class="input-group">
          <input placeholder="{{'PH_ENTER_NEW_PASSWORD' | translate}}" autocomplete="new-password" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="form-control" type="password">
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer" (click)="fieldTextType=!fieldTextType">
              <i class="fa" [ngClass]="{'fa-eye-slash':!fieldTextType,'fa-eye': fieldTextType}"></i>
            </span>
          </div>
        </div>
          <div *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').errors">
            <div class="text-danger" *ngIf="changePasswordForm.get('password').errors?.required">
              {{'PASSWORD_IS_REQUIRED' | translate}}
            </div>
            <div class="text-danger" *ngIf="changePasswordForm.get('password').errors?.minlength">
              {{'YOU_HAVE_TO_ENTER_AT_LEAST_6_DIGIT' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-0">
        <div class="form-group">
          <label>{{'CONFIRM_PASSWORD' | translate}}</label>
          <div class="input-group">
          <input placeholder="{{'PH_RETYPE_PASSWORD' | translate}}" autocomplete="new-password" [type]="fieldTextType ? 'text' : 'password'" formControlName="confirmPassword" class="form-control" type="password">
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer" (click)="fieldTextType=!fieldTextType">
              <i class="fa" [ngClass]="{'fa-eye-slash':!fieldTextType,'fa-eye': fieldTextType}"></i>
            </span>
          </div>
        </div>
          <div
            *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').errors">
            <div class="text-danger" *ngIf="changePasswordForm.get('confirmPassword').errors?.required">
              {{'CONFIRM_PASSWORD_IS_REQUIRED' | translate}}
            </div>
          </div>
          <div class="text-danger" *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.hasError('notSame')">
            {{'PASSWORDS_DO_NOT_MATCH' | translate}}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer pb-0">
  <div class="py-0 m-0" mat-dialog-actions>
    <button class="btn btn-success btn-sm m-right-10" (click)="changePassword()" cdkFocusInitial><i
        class="fas fa-save"></i>
        <span *ngIf="!disabled"> {{'SAVE' | translate }}</span> 
      <mat-spinner class="mat-spinner-color-white" color="primary" [diameter]="25" *ngIf="disabled"></mat-spinner>
        </button>
    <button class="btn btn-danger btn-sm" (click)="onNoClick()"><i class="fas fa-times-circle"></i> {{'CANCEL' |
      translate}}</button>
  </div>
</div>
</div>
